import React from "react";
import { NavLink } from "react-router-dom";
import Switcher from "../Elements/Switcher";

class Footer extends React.Component {
    render() {
        const items = this.props.categories;

        console.log("const items = ", JSON.stringify(items));

        return (
            <>
                <footer className="site-footer footer-large  footer-dark	footer-wide">
                    {/* FOOTER BLOCKES START */}
                    <div className="footer-top overlay-wraper">
                        <div className="overlay-main" />
                        <div className="container">
                            <div className="row">
                                {/* ABOUT COMPANY */}
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    <div className="widget widget_about">
                                        {/*<h4 class="widget-title">About Company</h4>*/}
                                        <div className="logo-footer clearfix p-b15">
                                            <NavLink to={"./"}>
                                                <img src={require("./../../assets/page_media/home/Florail_Logo_2.png")} alt="Inteshape" />
                                            </NavLink>
                                        </div>
                                        {/* <p>7X Theme is a html template for interior and architecture purpose. Today we can tell you, thanks to your passion.</p> */}
                                        {/* <p>The name of our long-standing enterprise hints of our area of expertise.</p> */}
                                        <ul className="social-icons  sx-social-links">
                                            {/* <li><a href="https://www.behance.net/" className="fa fa-behance" target="_blank"></a></li> */}
                                            <li>
                                                <a href="https://www.facebook.com" className="fa fa-facebook" target="_blank" rel="noreferrer"></a>
                                            </li>
                                            {/* <li><a href="https://twitter.com" className="fa fa-twitter" target="_blank"></a></li> */}
                                            <li>
                                                <a href="https://www.instagram.com" className="fa fa-instagram" target="_blank" rel="noreferrer"></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {/* RESENT POST */}
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    <div className="widget widget_services inline-links">
                                        <h5 className="widget-title">Products</h5>
                                        <ul>
                                            {items?.aluminium?.map((el, index) => {
                                                return (
                                                    <li key={index}>
                                                        <NavLink to={`/product/aluminium/${el.name}`}>{el.name}</NavLink>
                                                    </li>
                                                );
                                            })}
                                            {items?.steel?.map((el, index) => {
                                                return (
                                                    <li key={index}>
                                                        <NavLink to={`/product/steel/${el.name}`}>{el.name}</NavLink>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div>
                                {/* USEFUL LINKS */}
                                <div className="col-lg-3 col-md-6 col-sm-6 footer-col-3">
                                    <div className="widget widget_services inline-links">
                                        <h5 className="widget-title">Useful links</h5>
                                        <ul>
                                            <li>
                                                <NavLink to={"/"}>Home</NavLink>
                                            </li>

                                            <li>
                                                <NavLink to={"/about-us"}>About Us</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={"/gallery"}>Gallery</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={"/product/aluminium/"}>Product</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={"/contact-us"}>Contact Us</NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {/* CONTACT US */}
                                <div className="col-lg-3 col-md-6 col-sm-6">
                                    <div className="widget widget_address_outer">
                                        <h5 className="widget-title">Contact Us</h5>
                                        <ul className="widget_address">
                                            <li>50 ft main road, Kothariya main road, Rajkot, 360002</li>
                                            <li>florailindustries@gmail.com</li>
                                            <li>+91 98986 55408</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* FOOTER COPYRIGHT */}
                    <div className="footer-bottom overlay-wraper">
                        <div className="overlay-main" />
                        <div className="container">
                            <div className="row">
                                <div className="sx-footer-bot-left">
                                    <span className="copyrights-text">© DEVELOPED BY : Ekantik Technologies © COPYRIGHTS 2024 : : Florail Industries : : ALL RIGHTS RESERVED.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                <Switcher />
            </>
        );
    }
}

export default Footer;
