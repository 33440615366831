// import React from "react";
// import { useLocation } from "react-router-dom";
// import Banner from "./../Elements/Banner";
// import ReactPlayer from "react-player";
// import ImageGallery from "react-image-gallery";

// const withRouter = (Component) => {
//     return (props) => {
//         const location = useLocation();
//         console.log("location ==>", location);
//         return <Component {...props} location={location} />;
//     };
// };

// var bnrimg = require("./../../assets/page_media/product/ProductBanner.jpg");

// const images = [
//     {
//         original: require("./../../assets/page_media/product/Aluminium_Bottom_Profile_FR01.jpg"),
//         thumbnail: require("./../../assets/page_media/product/Aluminium_Bottom_Profile_FR01.jpg"),
//     },
//     {
//         original: require("./../../assets/page_media/product/Aluminium_Hand_Rail_FR51.jpg"),
//         thumbnail: require("./../../assets/page_media/product/Aluminium_Hand_Rail_FR51.jpg"),
//     },
//     {
//         original: require("./../../assets/page_media/product/Aluminum_Railing_Bracket_FR121.jpg"),
//         thumbnail: require("./../../assets/page_media/product/Aluminum_Railing_Bracket_FR121.jpg"),
//     },
//     {
//         original: require("./../../assets/page_media/product/Aluminum_Railing_Bracket_FR_101.jpg"),
//         thumbnail: require("./../../assets/page_media/product/Aluminum_Railing_Bracket_FR_101.jpg"),
//     },
// ];

// class ServiceDetail extends React.Component {
//     constructor(props) {
//         super(props);

//         // Access the product passed via state
//         const { product } = this.props.location.state || {};

//         this.state = {
//             product: product || {}, // Default to an empty object if no product is found
//         };
//     }

//     render() {
//         const location = this.props;

//         console.log("product ==>", location);

//         const containerStyle = {
//             maxWidth: "800px",
//             margin: "0 auto",
//             padding: "20px",
//             backgroundColor: "#f9f9f9",
//             borderRadius: "10px",
//             boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//         };

//         const sectionStyle = {
//             paddingTop: "1.25rem", // 5 * 0.25rem (Tailwind pt-5 equivalent)
//             marginBottom: "1.5rem", // 6 * 0.25rem (Tailwind mb-6 equivalent)
//         };

//         const headingStyle = {
//             fontSize: "1.5rem", // Tailwind text-2xl equivalent
//             fontWeight: "bold",
//             marginBottom: "1rem", // 4 * 0.25rem (Tailwind mb-4 equivalent)
//             color: "#333",
//             borderBottom: "2px solid #ddd",
//             paddingBottom: "0.5rem",
//         };

//         const paragraphStyle = {
//             fontSize: "1rem", // Tailwind text-base equivalent
//             lineHeight: "1.6",
//             marginBottom: "1rem", // 4 * 0.25rem (Tailwind mb-4 equivalent)
//             color: "#555",
//         };

//         const strongStyle = {
//             color: "#000",
//             fontWeight: "600",
//         };

//         const spanStyle = {
//             paddingLeft: "0.3125rem", // Tailwind pl-1.5 equivalent
//         };

//         const listStyle = {
//             display: "flex",
//             flexDirection: "column",
//             gap: "0.5rem", // Tailwind gap-2 equivalent
//             fontWeight: 400,
//             fontSize: "0.9rem", // Tailwind text-sm equivalent
//             color: "#555",
//             backgroundColor: "#fff",
//             padding: "10px",
//             borderRadius: "5px",
//             boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
//         };
//         return (
//             <>
//                 <div className="page-content">
//                     <Banner title="Product Details" pagename="Product Detail" bgimage={bnrimg} />
//                     {/* SECTION CONTENT START */}
//                     <div className="section-full p-t80 p-b50 mobile-page-padding">
//                         <div className="container">
//                             {/* GALLERY CONTENT START */}
//                             <div className="row">
//                                 <div className="col-md-12">
//                                     <div className="section-content">
//                                         <div className="service-single-block m-b30">
//                                             <div className="sx-media">
//                                                 <ImageGallery renderLeftNav={this.renderLeftNav} renderRightNav={this.renderRightNav} items={images} showPlayButton={false} />
//                                             </div>

//                                             <div style={containerStyle}>
//                                                 <div style={sectionStyle}>
//                                                     <h4 style={headingStyle}>Sizes</h4>
//                                                     <p style={paragraphStyle}>
//                                                         <strong style={strongStyle}>Profile Size: </strong>
//                                                         <span style={spanStyle}>70 mm X 140 mm</span>
//                                                     </p>
//                                                     <p style={paragraphStyle}>
//                                                         <strong style={strongStyle}>
//                                                             Bracket <i>(65mm)</i> - Weight:{" "}
//                                                         </strong>
//                                                         <span style={spanStyle}>0.400 gm</span>
//                                                     </p>
//                                                     <p style={paragraphStyle}>
//                                                         <strong style={strongStyle}>Weight: </strong>
//                                                         <span>
//                                                             0.700 gm <i>(1 Feet)</i>
//                                                         </span>
//                                                     </p>
//                                                     <p style={paragraphStyle}>
//                                                         <strong style={strongStyle}>Length: </strong>
//                                                         <span>13 ft / 16 ft</span>
//                                                     </p>
//                                                 </div>

//                                                 <div style={sectionStyle}>
//                                                     <h5 style={headingStyle}>Specification</h5>
//                                                     <p style={listStyle}>
//                                                         <span>{`> `}Top mounted bracket system with continuous cover profile</span>
//                                                         <span>{`> `}This profile can support glass height up to full height</span>
//                                                     </p>
//                                                 </div>

//                                                 <div style={sectionStyle}>
//                                                     <h5 style={headingStyle}>Glass</h5>
//                                                     <p style={listStyle}>
//                                                         <span> {`> `} 12 mm Toughened Glass</span>
//                                                         <span> {`> `} 25 mm Laminated Glass</span>
//                                                     </p>
//                                                 </div>

//                                                 <div style={sectionStyle}>
//                                                     <h5 style={headingStyle}>Finishes</h5>
//                                                     <p style={listStyle}>
//                                                         <span>{`> `} Wooden</span>
//                                                         <span>{`> `} Anodized</span>
//                                                         <span>{`> `} Powder Coating</span>
//                                                     </p>
//                                                 </div>

//                                                 <div style={sectionStyle}>
//                                                     <h5 style={headingStyle}>Features</h5>
//                                                     <p style={listStyle}>
//                                                         <span>{`> `} 100% Recyclable</span>
//                                                         <span>{`> `} Easy Cleaning</span>
//                                                         <span>{`> `} Maintenance Free</span>
//                                                         <span>{`> `} Weather Proof</span>
//                                                         <span>{`> `} Robust for Higher Safety</span>
//                                                     </p>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                             {/* GALLERY CONTENT END */}
//                         </div>
//                     </div>
//                     {/* SECTION CONTENT END  */}
//                 </div>
//                 <div className="modal fade" id="myModal6" role="dialog">
//                     <div className="modal-dialog">
//                         <div className="modal-content">
//                             <ReactPlayer url="https://www.youtube.com/watch?v=Oy2QIiSQT2U" />
//                         </div>
//                     </div>
//                 </div>
//             </>
//         );
//     }
// }

// export default withRouter(ServiceDetail);

import React from "react";
import { useLocation } from "react-router-dom";
import Banner from "./../Elements/Banner";
import ReactPlayer from "react-player";
import ImageGallery from "react-image-gallery";

var bnrimg = require("./../../assets/page_media/product/ProductBanner.jpg");

const images = [
    {
        original: require("./../../assets/page_media/product/Aluminium_Bottom_Profile_FR01.jpg"),
        thumbnail: require("./../../assets/page_media/product/Aluminium_Bottom_Profile_FR01.jpg"),
    },
    {
        original: require("./../../assets/page_media/product/Aluminium_Hand_Rail_FR51.jpg"),
        thumbnail: require("./../../assets/page_media/product/Aluminium_Hand_Rail_FR51.jpg"),
    },
    {
        original: require("./../../assets/page_media/product/Aluminum_Railing_Bracket_FR121.jpg"),
        thumbnail: require("./../../assets/page_media/product/Aluminum_Railing_Bracket_FR121.jpg"),
    },
    {
        original: require("./../../assets/page_media/product/Aluminum_Railing_Bracket_FR_101.jpg"),
        thumbnail: require("./../../assets/page_media/product/Aluminum_Railing_Bracket_FR_101.jpg"),
    },
];

const ServiceDetail = () => {
    const location = useLocation();
    const { product } = location.state || {}; // Access the product passed via state

    const imageGallery = product?.cloudinery_response?.map((el) => ({ original: el.secure_url, thumbnail: el.secure_url })) ?? [];

    const sections = product.sections;

    console.log("sections ==>", sections);

    const containerStyle = {
        maxWidth: "800px",
        margin: "0 auto",
        padding: "20px",
        backgroundColor: "#f9f9f9",
        borderRadius: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    };

    const sectionStyle = {
        paddingTop: "1.25rem",
        marginBottom: "1.5rem",
    };

    const headingStyle = {
        fontSize: "1.5rem",
        fontWeight: "bold",
        marginBottom: "1rem",
        color: "#333",
        borderBottom: "2px solid #ddd",
        paddingBottom: "0.5rem",
    };

    const paragraphStyle = {
        fontSize: "1rem",
        lineHeight: "1.6",
        marginBottom: "1rem",
        color: "#555",
    };

    const strongStyle = {
        color: "#000",
        fontWeight: "600",
    };

    const spanStyle = {
        paddingLeft: "0.3125rem",
    };

    const listStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
        fontWeight: 400,
        fontSize: "0.9rem",
        color: "#555",
        backgroundColor: "#fff",
        padding: "10px",
        borderRadius: "5px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
    };

    return (
        <>
            <div className="page-content">
                <Banner title="Product Details" pagename="Product Detail" bgimage={bnrimg} />
                <div className="section-full p-t80 p-b50 mobile-page-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-content">
                                    <div className="service-single-block m-b30">
                                        <div className="sx-media">
                                            <ImageGallery items={imageGallery} showPlayButton={false} />{" "}
                                        </div>

                                        {!(sections.length === 1 && !sections[0].details.length) && (
                                            <div style={containerStyle}>
                                                {sections.map((el, index) => {
                                                    if (el.detailsType !== "single") {
                                                        return (
                                                            <div style={sectionStyle} key={index}>
                                                                <h4 style={headingStyle}>{el.sectionHeading}</h4>
                                                                {el.details.map((deEl, index) => {
                                                                    return (
                                                                        <p style={paragraphStyle}>
                                                                            <strong style={strongStyle}>{deEl.title}: </strong>
                                                                            <span style={spanStyle}>{deEl.desc}</span>
                                                                        </p>
                                                                    );
                                                                })}
                                                            </div>
                                                        );
                                                    }

                                                    return (
                                                        <div style={sectionStyle} key={index}>
                                                            <h5 style={headingStyle}>{el.sectionHeading}</h5>
                                                            <p style={listStyle}>
                                                                {el.details.map((deEl, index) => {
                                                                    return (
                                                                        <span key={index}>
                                                                            {`> `}
                                                                            {deEl.value}
                                                                        </span>
                                                                    );
                                                                })}
                                                            </p>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="myModal6" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <ReactPlayer url="https://www.youtube.com/watch?v=Oy2QIiSQT2U" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ServiceDetail;
