import React from "react";
import { motion } from "framer-motion";
import { categories } from "./ShopSidebar";
import { NavLink } from "react-router-dom";

const bgimg1 = require("./../../images/background/bg-5.png");
const bgimg3 = require("./../../images/background/cross-line2.png");

class WhatWeDo1 extends React.Component {
    render() {
        const items = [
            { title: "Gold Glass Railing Pipe", imgSrc: require("../../assets/page_media/gallery/01 FR 16.jpg"), link: "steel" },
            { title: "Steel Glass Railing Bracket", imgSrc: require("../../assets/page_media/gallery/01 FR 20.jpg"), link: "steel" },
            { title: "Aluminium Glass Accessories", imgSrc: require("../../assets/page_media/home/whatWeDo/Aluminium Glass Accessories 1.jpg"), link: "aluminium" },
            { title: "Aluminium Hand Rail", imgSrc: require("../../assets/page_media/home/whatWeDo/Aluminium Hand Rail 1.jpg"), link: "aluminium" },
            { title: "Bracket System", imgSrc: require("../../assets/page_media/home/whatWeDo/Bracket System 1.jpg"), link: "aluminium" },
            { title: "Bracket With Cover Profile", imgSrc: require("../../assets/page_media/home/whatWeDo/Bracket With Cover Profile 1.jpg"), link: "aluminium" },
            { title: "Continues", imgSrc: require("../../assets/page_media/home/whatWeDo/Continues 1.jpg"), link: "aluminium" },
        ];

        const containerVariants = {
            hidden: { opacity: 0, y: 50 },
            visible: {
                opacity: 1,
                y: 0,
                transition: {
                    type: "spring",
                    stiffness: 50,
                    damping: 20,
                    staggerChildren: 0.3,
                },
            },
        };

        const itemVariants = {
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
        };

        return (
            <div className="section-full mobile-page-padding bg-white p-t80 p-b30 bg-repeat overflow-hide" style={{ backgroundImage: `url(${bgimg1})` }}>
                <div className="container right-half-bg-image-outer">
                    <div className="right-half-bg-image bg-parallax bg-fixed bg-top-right" data-stellar-background-ratio={0} style={{ backgroundImage: `url(${bgimg1})` }} />
                    <div className="section-head">
                        <div className="sx-separator-outer separator-left">
                            <div className="sx-separator bg-white bg-moving bg-repeat-x" style={{ backgroundImage: `url(${bgimg3})` }}>
                                <motion.h3 className="sep-line-one" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
                                    What We Do
                                </motion.h3>
                            </div>
                        </div>
                    </div>
                    <motion.div className="section-content" variants={containerVariants} initial="hidden" animate="visible">
                        <div className="row number-block-one-outer justify-content-center">
                            {items.map((item, index) => (
                                <motion.div
                                    key={index}
                                    className="col-lg-4 col-md-6 col-sm-12 m-b30"
                                    variants={itemVariants}
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true, amount: 0.3 }}
                                >
                                    <div className="animate-in-to-top">
                                        <motion.img
                                            src={item.imgSrc}
                                            alt={item.title}
                                            initial={{ opacity: 0, scale: 0.95 }}
                                            whileInView={{ opacity: 1, scale: 1 }}
                                            transition={{ duration: 0.5 }}
                                            style={{ width: "100%", height: "auto" }}
                                        />
                                        <div className="figcaption bg-white text-center p-a20">
                                            <h4 className="m-a0" style={{ fontSize: "1rem" }}>
                                                <NavLink to={`/product/${item.link}`}>{categories[index]?.name || item.title}</NavLink>
                                            </h4>
                                        </div>
                                    </div>
                                </motion.div>
                            ))}
                        </div>
                    </motion.div>
                </div>
            </div>
        );
    }
}

export default WhatWeDo1;
