import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import ReactPlayer from "react-player";

// Import the background image
const bgimg1 = require("../../assets/page_media/about_us/FlorailClients.jpg");

const About3 = ({ bgcolor }) => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect();
                }
            },
            { threshold: 0.1 } // Adjust this as needed
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <>
            <motion.div
                className={`${bgcolor} section-full mobile-page-padding p-t80 p-b50`}
                initial={{ opacity: 0, y: 50 }}
                animate={isVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
                transition={{ duration: 2.5, ease: "easeOut" }}
                ref={sectionRef}
                style={{ overflowX: "hidden" }} // Prevent horizontal scrolling
            >
                <div className="container">
                    <div className="section-content">
                        <div className="row">
                            <motion.div
                                className="col-xl-6 col-lg-6 col-md-12 mt-5"
                                initial={{ opacity: 0, x: -50 }}
                                animate={isVisible ? { opacity: 1, x: 0 } : { opacity: 0, x: -50 }}
                                transition={{ duration: 1, ease: "easeOut" }}
                            >
                                <div className="about-home-3 m-b30 bg-white py-5 my-4">
                                    <h3 className="m-t0 m-b20 sx-title">Florail & Client</h3>
                                    <p>
                                        Our enormous success in the domestic market is solely based on our reliable, responsible & quality conscious clients. Throughout the year we
                                        have been able to maintain & develop a strong relationship with our clients. The cordial relationship with our clients allows us to supply a
                                        premium range of precision steel railing as well as fittings. We ensure the quality of our sourced products meets the international standard
                                        for quality. Further, we endeavor to maintain a long-term relationship with our widespread clients.
                                    </p>
                                </div>
                            </motion.div>
                            <motion.div
                                className="col-xl-6 col-lg-6 col-md-12"
                                initial={{ opacity: 0, x: 50 }}
                                animate={isVisible ? { opacity: 1, x: 0 } : { opacity: 0, x: 50 }}
                                transition={{ duration: 1, ease: "easeOut" }}
                            >
                                <div className="video-section-full-v2">
                                    <div className="video-section-full bg-no-repeat bg-cover bg-center overlay-wraper m-b30" style={{ backgroundImage: `url(${bgimg1})` }}>
                                        <div className="overlay-main bg-black opacity-04" />
                                        <div className="video-section-inner">
                                            <div className="video-section-content"></div>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </motion.div>

            <div className="modal fade" id="myModal" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <ReactPlayer url="https://vimeo.com/34741214" />
                    </div>
                </div>
            </div>

            <style jsx>{`
                @media (max-width: 767px) {
                    .section-content {
                        padding: 0 15px;
                    }
                }

                @media (max-width: 575px) {
                    .video-section-full {
                        background-position: center center;
                        background-size: cover;
                    }
                }
            `}</style>
        </>
    );
};

export default About3;
