import React, { useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";

export const categories = [
    { name: "Aluminium Railing Bottom Profile", count: 28 },
    { name: "Aluminium Railing Brecket", count: 5 },
    { name: "Aluminium Hand Rail", count: 24 },
    { name: "Aluminium Railing Brecket", count: 15 },
    { name: "Aluminium Railing Accessories", count: 20 },
    { name: "Glass Railing Brecket", count: 90 },
    { name: "Glass Railing Pipe", count: 90 },
    { name: "Invisible Grill Accessories", count: 90 },
];

function ShopSidebar({ typeWiseCategory }) {
    useEffect(() => {
        const loadScript = (src) => {
            return new Promise(function (resolve, reject) {
                var script = document.createElement("script");
                script.src = src;
                script.addEventListener("load", function () {
                    resolve();
                });
                script.addEventListener("error", function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            });
        };

        loadScript("./assets/js/custom.js");
    }, []);

    const { type } = useParams();

    return (
        <>
            <div className="side-bar p-a30 bg-gray">
                <div className="widget widget_services ">
                    <h4 className="widget-title">Categories</h4>
                    <ul className="p-a10 bg-white">
                        {typeWiseCategory?.map((item, index) => (
                            <li key={index}>
                                <NavLink to={`/product/${type}/${item.name}`}>
                                    {item.name}
                                    {/* <span> ({item.count})</span> */}
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    );
}

export default ShopSidebar;
