import axios from "axios";

const API_URL = "https://florail-backend.vercel.app/api";

export const getAluminiumTypes = async () => {
    const response = await axios.get(`${API_URL}/aluminium/types`);
    return response.data;
};

export const getSteelTypes = async () => {
    const response = await axios.get(`${API_URL}/steel/types`);
    return response.data;
};
