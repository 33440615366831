import React from "react";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";
import About2 from "./../Elements/About2";
import About3 from "../Elements/About3";
import EthicsSection from "../Elements/EthicsSection";

var bnrimg = require("../../assets/page_media/about_us/About_Us_banner.jpg");

class About1 extends React.Component {
    componentDidMount() {
        function loadScript(src) {
            return new Promise(function (resolve, reject) {
                var script = document.createElement("script");
                script.src = src;
                script.addEventListener("load", function () {
                    resolve();
                });
                script.addEventListener("error", function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            });
        }

        loadScript("./assets/js/custom.js");
    }
    render() {
        return (
            <>
                <div className="page-content">
                    <Banner title="About Us" pagename="About Us" description="" bgimage={bnrimg} />
                    <About2 />
                    <EthicsSection />
                    {/* <WhatWeDo6 /> */}
                    <About3 bgcolor="bg-gray" />

                    {/* <Achievements1 /> */}
                    {/* <Team2 /> */}
                    {/* <Testimonials2 separatoralignment="separator-center" /> */}
                </div>
            </>
        );
    }
}

export default About1;
