// import React from "react";
// import { motion, useAnimation, useInView } from "framer-motion";

// const SectionWithOverlay = () => {
//     const controls = useAnimation();
//     const ref = React.useRef(null);
//     const isInView = useInView(ref, { once: true });

//     React.useEffect(() => {
//         if (isInView) {
//             controls.start("visible");
//         }
//     }, [isInView, controls]);

//     const variants = {
//         hidden: { opacity: 0, y: 100 },
//         visible: { opacity: 1, y: 0 },
//     };

//     return (
//         <motion.div
//             ref={ref}
//             initial="hidden"
//             animate={controls}
//             transition={{ duration: 2, ease: "easeOut" }}
//             variants={variants}
//             style={{
//                 position: "relative",
//                 backgroundImage: "url(https://5.imimg.com/data5/ANDROID/Default/2023/4/301865131/VY/UH/SB/145812749/product-jpeg.jpg)", // replace with your image URL
//                 backgroundSize: "cover",
//                 backgroundPosition: "center",
//                 height: "50vh",
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//                 color: "white",
//                 textAlign: "center",
//             }}
//         >
//             <motion.div
//                 style={{
//                     position: "absolute",
//                     top: 0,
//                     left: 0,
//                     right: 0,
//                     bottom: 0,
//                     backgroundColor: "rgba(0, 0, 0, 0.80)",
//                 }}
//             />
//             <motion.div
//                 initial={{ opacity: 0, x: -50 }}
//                 animate={{ opacity: 1, x: 0 }}
//                 transition={{ duration: 1, ease: "easeOut" }}
//                 style={{
//                     position: "relative",
//                     zIndex: 1,
//                     padding: "20px",
//                     maxWidth: "80vw",
//                     border: "1px solid white",
//                     borderRadius: "8px",
//                     "@media (maxWidth: 600px)": {
//                         maxWidth: "90vw",
//                         padding: "15px",
//                     },
//                 }}
//             >
//                 <h1
//                     style={{
//                         color: "#E74D3C",
//                         fontSize: 38,
//                         fontWeight: 600,
//                         "@media (maxWidth: 600px)": {
//                             fontSize: "28px",
//                         },
//                     }}
//                 >
//                     Our Ethics
//                 </h1>
//                 <p
//                     style={{
//                         fontWeight: 400,
//                         fontSize: 14,
//                         textAlign: "center",
//                         lineHeight: 2,
//                         letterSpacing: 1.5,
//                         "@media (maxWidth: 600px)": {
//                             fontSize: "12px",
//                             lineHeight: 1.5,
//                         },
//                     }}
//                 >
//                     We are a perfectionist in introducing uniquely designed Aluminum glass railing, Modular railing, Balcony railing, stainless steel balcony railing, Glass stair
//                     railing, Glass deck railing and other railing. Each piece in our portfolio excellently showcases creativity of our designers, who put their imagination and
//                     strength in designing railing.
//                 </p>
//             </motion.div>
//         </motion.div>
//     );
// };

// export default SectionWithOverlay;

import React from "react";
import { motion, useAnimation, useInView } from "framer-motion";
import styled from "styled-components";

const Section = styled(motion.div)`
    position: relative;
    background-image: url("https://5.imimg.com/data5/ANDROID/Default/2023/4/301865131/VY/UH/SB/145812749/product-jpeg.jpg"); // replace with your image URL
    background-size: cover;
    background-position: center;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    @media (max-width: 768px) {
        height: 60vh;
    }
    @media (max-width: 480px) {
        height: 70vh;
    }
`;

const Overlay = styled(motion.div)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
`;

const Content = styled(motion.div)`
    position: relative;
    z-index: 1;
    padding: 20px;
    max-width: 80vw;
    border: 1px solid white;
    border-radius: 8px;
    @media (max-width: 600px) {
        max-width: 90vw;
        padding: 15px;
    }
`;

const Title = styled.h1`
    color: #e74d3c;
    font-size: 38px;
    font-weight: 600;
    @media (max-width: 600px) {
        font-size: 28px;
    }
`;

const Paragraph = styled.p`
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    line-height: 2;
    letter-spacing: 1.5;
    @media (max-width: 600px) {
        font-size: 14px;
        line-height: 1.8;
    }
`;

const SectionWithOverlay = () => {
    const controls = useAnimation();
    const ref = React.useRef(null);
    const isInView = useInView(ref, { once: true });

    React.useEffect(() => {
        if (isInView) {
            controls.start("visible");
        }
    }, [isInView, controls]);

    const variants = {
        hidden: { opacity: 0, y: 100 },
        visible: { opacity: 1, y: 0 },
    };

    return (
        <Section ref={ref} initial="hidden" animate={controls} transition={{ duration: 2, ease: "easeOut" }} variants={variants}>
            <Overlay />
            <Content initial={{ opacity: 0, x: -50 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 1, ease: "easeOut" }}>
                <Title>Our Ethics</Title>
                <Paragraph>
                    We are a perfectionist in introducing uniquely designed Aluminum glass railing, Modular railing, Balcony railing, stainless steel balcony railing, Glass stair
                    railing, Glass deck railing and other railing. Each piece in our portfolio excellently showcases creativity of our designers, who put their imagination and
                    strength in designing railing.
                </Paragraph>
            </Content>
        </Section>
    );
};

export default SectionWithOverlay;
