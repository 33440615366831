import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home1 from "./Pages/Home1";

import About1 from "./Pages/About1";

import ServiceDetail from "./Pages/ServiceDetail";

import Team2 from "./Pages/Team2";

import ShopGrid from "./Pages/ShopGrid";

import ContactUs from "./Pages/ContactUs";
import ScrollToTop from "./Common/ScrollToTop";
import Header from "./Common/Header";
import axios from "axios";
import { getAluminiumTypes, getSteelTypes } from "../services/apiService";
import Footer from "./Common/Footer";

function Components() {
    const [typeWiseCategory, setTypeWiseCategory] = useState({ aluminium: [], steel: [] });

    const [productCategoryWise, setProductCategoryWise] = useState({ aluminium: [], steel: [] });

    console.log("productCategoryWise ==>", productCategoryWise);

    const getProducts = async () => {
        try {
            const response = await axios.get(`https://florail-backend.vercel.app/api/products`);
            console.log("const response.data = ", response.data);
            processProducts(response.data);
        } catch (error) {
            console.log("error ==>", error);
        }
    };

    const processProducts = (data) => {
        const aluminiumProducts = data.aluminiumProducts;
        const aluminiumCategoryWise = aluminiumProducts.reduce((acc, product) => {
            const { type } = product;
            if (!acc[type]) {
                acc[type] = [];
            }
            acc[type].push(product);
            return acc;
        }, {});

        const steelProducts = data.steelProducts;
        const steelCategoryWise = steelProducts.reduce((acc, product) => {
            const { type } = product;
            if (!acc[type]) {
                acc[type] = [];
            }
            acc[type].push(product);
            return acc;
        }, {});

        setProductCategoryWise(() => ({
            steel: steelCategoryWise,
            aluminium: aluminiumCategoryWise,
        }));
    };

    useEffect(() => {
        getProducts();

        const getData = async () => {
            const steelData = await getSteelTypes();

            const aluminiumData = await getAluminiumTypes();

            setTypeWiseCategory({ aluminium: aluminiumData, steel: steelData });
        };

        getData();
        getAluminiumTypes();
    }, []);

    return (
        <BrowserRouter basename="/">
            <ScrollToTop />
            <div className="page-wraper">
                <Header />

                <Routes>
                    {/* <-- done // */}
                    <Route path="/" element={<Home1 categories={typeWiseCategory} />} />
                    <Route path="/about-us" element={<About1 />} />
                    <Route path="/gallery" element={<Team2 />} />
                    <Route path="/product/:type" element={<ShopGrid productCategoryWise={productCategoryWise} typeWiseCategory={typeWiseCategory} />} />
                    <Route path="/product/:type/:category" element={<ShopGrid productCategoryWise={productCategoryWise} typeWiseCategory={typeWiseCategory} />} />
                    <Route path="/product-detail" element={<ServiceDetail />} />
                    <Route path="/contact-us" element={<ContactUs />} />
                    {/* // done --> */}
                </Routes>
                <Footer categories={typeWiseCategory} />
            </div>
        </BrowserRouter>
    );
}

export default Components;
