import React from "react";
import Banner from "./../Elements/Banner";
import { motion, useAnimation } from "framer-motion";
import { InView } from "react-intersection-observer";

const teamMembers = [
    {
        image: require("./../../assets/page_media/home/whatWeDo/Aluminium Glass Accessories 1.jpg"),
        membername: "Johnny Jackman",
        position: "Architect",
    },
    {
        image: require("./../../assets/page_media/home/whatWeDo/Aluminium Hand Rail 1.jpg"),
        membername: "Daniel Rickman",
        position: "Architect",
    },
    {
        image: require("./../../assets/page_media/home/whatWeDo/Bracket System 1.jpg"),
        membername: "Mark Norwich",
        position: "Finances",
    },
    {
        image: require("./../../assets/page_media/home/whatWeDo/Bracket With Cover Profile 1.jpg"),
        membername: "Johnny Jackman",
        position: "Architect",
    },
    {
        image: require("./../../assets/page_media/home/whatWeDo/Continues 1.jpg"),
        membername: "Daniel Rickman",
        position: "Architect",
    },
    {
        image: require("./../../assets/page_media/gallery/01 FR 14.jpg"),
        membername: "Mark Norwich",
        position: "Finances",
    },
    {
        image: require("./../../assets/page_media/gallery/01 FR 15.jpg"),
        membername: "Mark Norwich",
        position: "Finances",
    },
    {
        image: require("./../../assets/page_media/gallery/01 FR 16.jpg"),
        membername: "Mark Norwich",
        position: "Finances",
    },
    {
        image: require("./../../assets/page_media/gallery/01 FR 17.jpg"),
        membername: "Mark Norwich",
        position: "Finances",
    },
    {
        image: require("./../../assets/page_media/gallery/01 FR 18.jpg"),
        membername: "Mark Norwich",
        position: "Finances",
    },
    {
        image: require("./../../assets/page_media/gallery/01 FR 19.jpg"),
        membername: "Mark Norwich",
        position: "Finances",
    },
    {
        image: require("./../../assets/page_media/gallery/01 FR 20.jpg"),
        membername: "Mark Norwich",
        position: "Finances",
    },
    {
        image: require("./../../assets/page_media/gallery/01 FR 21.jpg"),
        membername: "Mark Norwich",
        position: "Finances",
    },
    {
        image: require("./../../assets/page_media/gallery/01 FR 22.jpg"),
        membername: "Mark Norwich",
        position: "Finances",
    },
];

var bnrimg = require("./../../../src/assets/page_media/gallery/GalleryBanner.jpg");

const animationVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
};

const Team2 = () => {
    const controls = useAnimation();

    const handleInView = (inView) => {
        if (inView) {
            controls.start("visible");
        }
    };

    return (
        <>
            <div className="page-content">
                <Banner title="Gallery" pagename="Gallery" bgimage={bnrimg} />
                <div className="section-full p-t80 p-b50 mobile-page-padding">
                    <div className="container">
                        <div className="section-content">
                            <InView onChange={handleInView} threshold={0.1}>
                                {({ ref, inView }) => (
                                    <div ref={ref} className="row">
                                        {teamMembers.map((item, index) => (
                                            <motion.div
                                                className="col-xl-4 col-lg-4 col-md-6 col-sm-12 m-b30"
                                                key={index}
                                                initial="hidden"
                                                animate={inView ? "visible" : "hidden"}
                                                variants={animationVariants}
                                                transition={{ delay: index * 0.2, duration: 0.5 }}
                                            >
                                                <div className="our-team-3">
                                                    <div className="our-team-info">
                                                        <img src={item.image} alt="" />
                                                    </div>
                                                </div>
                                            </motion.div>
                                        ))}
                                    </div>
                                )}
                            </InView>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Team2;
