import React from "react";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import Slider1 from "./../Elements/Slider1";
import About1 from "./../Elements/About1";
import WhatWeDo1 from "./../Elements/WhatWeDo1";
import Testimonials1 from "./../Elements/Testimonials1";
import Projects1 from "./../Elements/Projects1";

class Home1 extends React.Component {
    render() {
        return (
            <>
                <div className="page-content">
                    <Slider1 />
                    <About1 />
                    <WhatWeDo1 categories={this.props.categories} />
                    <Testimonials1 />
                    {/* <Services1 /> */}
                    {/* <Projects1 /> */}
                    {/* <ClientsLogo1 /> */}
                    {/* <Team1 /> */}
                </div>
            </>
        );
    }
}

export default Home1;
