import React, { useRef } from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { motion, useInView } from "framer-motion";

const images = [
    require("./../../assets/page_media/home/about_us_front_1.jpg"),
    require("./../../assets/page_media/home/about_us_front_2.jpg"),
    require("./../../assets/page_media/home/about_us_front_3.jpg"),
];

var bgimg1 = require("./../../images/background/bg-4.png");
var bgimg2 = require("./../../images/background/cross-line2.png");

const fadeInUp = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0, transition: { duration: 1.2 } },
};

const staggerContainer = {
    hidden: { opacity: 1 },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.6,
        },
    },
};

const About1 = () => {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true, amount: 0.2 });

    const options = {
        loop: true,
        autoplay: true,
        margin: 30,
        nav: true,
        dots: true,
        navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
        responsive: {
            0: {
                items: 1,
            },
            991: {
                items: 1,
            },
        },
    };

    return (
        <>
            <motion.div
                className="section-full mobile-page-padding p-t80 p-b80 bg-white bg-no-repeat bg-bottom-left"
                style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                ref={ref}
                initial="hidden"
                animate={isInView ? "visible" : "hidden"}
                variants={staggerContainer}
            >
                <div className="container">
                    {/* TITLE START */}
                    <motion.div className="section-head" variants={fadeInUp}>
                        <div className="sx-separator-outer separator-left">
                            <div className="sx-separator bg-white bg-moving bg-repeat-x" style={{ backgroundImage: "url(" + bgimg2 + ")" }}>
                                <h3 className="sep-line-one">About us</h3>
                            </div>
                        </div>
                    </motion.div>
                    {/* TITLE END */}
                    <div className="section-content">
                        <div className="row">
                            <motion.div className="col-lg-5 col-md-12 col-sm-12" variants={fadeInUp}>
                                <div className="about-home-left">
                                    <h3 className="m-t0 sx-tilte">The World of Florail</h3>
                                    <p className="" style={{ textAlign: "justify" }}>
                                        The name of our long-standing enterprise hints of our area of expertise. We
                                        <b> FLORAIL INDUSTRIES</b>, are a railing manufacturer and supplier, widely accepted for introducing matchless quality and designer array of
                                        railing, in aluminum, stainless steel and glass.
                                    </p>

                                    <div className="text-left mt-5" style={{ paddingTop: 44 }}>
                                        <NavLink to="/about-us" className="site-button-secondry btn-half">
                                            <span>Read More</span>
                                        </NavLink>
                                    </div>
                                </div>
                            </motion.div>
                            <motion.div className="col-lg-7 col-md-12 col-sm-12" variants={fadeInUp}>
                                <div className="about-home-right">
                                    <OwlCarousel className="owl-carousel about-home number-slider owl-btn-vertical-center" {...options}>
                                        {images.map((item, index) => (
                                            <div className="item" key={index}>
                                                <div className="sx-img-effect zoom-slow">
                                                    <NavLink to={"/about-us"}>
                                                        <img src={item} alt="" />
                                                    </NavLink>
                                                </div>
                                            </div>
                                        ))}
                                    </OwlCarousel>
                                    <div className="about-home-before">
                                        <img src={require("./../../assets/page_media/home/about_us_back.jpg")} alt="" />
                                    </div>
                                </div>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </motion.div>
        </>
    );
};

export default About1;
