import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import React, { useEffect, useState } from "react";
import "./style.css";

export const TypingEffect = ({ text, speed }) => {
    const [displayText, setDisplayText] = useState("");
    const [index, setIndex] = useState(0);

    useEffect(() => {
        if (index < text.length) {
            const timeout = setTimeout(() => {
                setDisplayText((prev) => prev + text[index]);
                setIndex(index + 1);
            }, speed);
            return () => clearTimeout(timeout);
        }
    }, [index, text, speed]);

    return <span>{displayText}</span>;
};

var bgimg1 = require("./../../images/background/bg5.jpg");

const About2 = () => {
    const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

    return (
        <motion.div
            className="section-full mobile-page-padding p-t80 p-b80 bg-gray"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 50 }}
            transition={{ duration: 0.8 }}
            style={{ overflowX: "hidden" }} // Prevent horizontal scrolling
        >
            <div className="container">
                <div className="section-content">
                    <div className="row">
                        <motion.div
                            className="col-xl-5 col-lg-5 col-md-12"
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: inView ? 1 : 0, scale: inView ? 1 : 0.8 }}
                            transition={{ duration: 0.8, delay: 0.2 }}
                        >
                            <div
                                className="home-2-about bg-bottom-left bg-no-repeat bg-cover"
                                style={{ backgroundImage: `url(${bgimg1})`, backgroundSize: "cover", backgroundPosition: "bottom left" }}
                            ></div>
                        </motion.div>
                        <motion.div
                            className="col-xl-7 col-lg-7 col-md-12"
                            initial={{ opacity: 0, x: 50 }}
                            animate={{ opacity: inView ? 1 : 0, x: inView ? 0 : 50 }}
                            transition={{ duration: 0.8, delay: 0.4 }}
                        >
                            <div className="about-home-2">
                                <h3 className="m-t0 sx-tilte">
                                    <TypingEffect text="The World of Florail" speed={100} />
                                </h3>
                                <p className="" style={{ textAlign: "justify" }}>
                                    The name of our long-standing enterprise hints of our area of expertise. We
                                    <b> FLORAIL INDUSTRIES</b>, are a railing manufacturer and supplier, widely accepted for introducing matchless quality and designer array of
                                    railing, in aluminum, stainless steel, and glass.
                                </p>
                                <p className="" style={{ textAlign: "justify" }} ref={ref}>
                                    We are a perfectionist in introducing uniquely designed aluminum glass railing, modular railing, balcony railing, stainless steel balcony
                                    railing, glass stair railing, glass deck railing, and other railings. Each piece in our portfolio excellently showcases the creativity of our
                                    designers, who put their imagination and strength into designing railing.
                                </p>
                                <div className="text-left">{/* <NavLink to={"/about-2"} className="site-button-link">Read More</NavLink> */}</div>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default About2;
