import React, { useState } from "react";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";
import GoogleMapReact from "google-map-react";
import axios from "axios";
import { motion, useAnimation } from "framer-motion";
import { InView } from "react-intersection-observer";
import "./map.css";

var bnrimg = require("./../../assets/page_media/Contact_us/Contact_us.jpg");

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const ContactUs = () => {
    const [formData, setFormData] = useState({
        username: "",
        email: "",
        message: "",
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const { username, email, message } = formData;

        try {
            const response = await axios.post(`https://florail-backend.vercel.app/api/send-inquiry`, {
                username,
                email,
                message,
            });
            console.log(response.data); // Handle the response as needed
            // Optionally, reset the form
            setFormData({ username: "", email: "", message: "" });
        } catch (error) {
            console.error("There was an error sending the inquiry:", error);
        }
    };

    const defaultProps = {
        center: {
            lat: 34.07328,
            lng: -118.25141,
        },
        zoom: 12,
    };

    const animationVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0 },
    };

    const controls = useAnimation();

    const handleInView = (inView) => {
        if (inView) {
            controls.start("visible");
        }
    };

    const { username, email, message } = formData;

    return (
        <>
            <div className="page-content">
                <Banner title="Contact Us" pagename="Contact us" bgimage={bnrimg} />
                {/* SECTION CONTENT START */}
                <div className="section-full p-tb80 inner-page-padding">
                    {/* LOCATION BLOCK*/}
                    <div className="container">
                        {/* GOOGLE MAP & CONTACT FORM */}
                        <div className="section-content">
                            {/* CONTACT FORM*/}
                            <InView onChange={handleInView} threshold={0.5}>
                                {({ ref, inView }) => (
                                    <div ref={ref} className="row">
                                        <motion.div
                                            className="col-lg-8 col-md-12 col-sm-12"
                                            initial="hidden"
                                            animate={controls}
                                            variants={animationVariants}
                                            transition={{ delay: 0.2, duration: 0.5 }}
                                        >
                                            <div className="contact-form cons-contact-form bg-gray p-a30">
                                                <div className="contact-one">
                                                    {/* TITLE START */}
                                                    <div className="section-head">
                                                        <div className="sx-separator-outer separator-left">
                                                            <div
                                                                className="sx-separator bg-white bg-moving bg-repeat-x"
                                                                style={{ backgroundImage: "url(images/background/cross-line2.png)" }}
                                                            >
                                                                <h3 className="sep-line-one">Get in Touch</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* TITLE END */}
                                                    <div className="form-group">
                                                        <input
                                                            name="username"
                                                            type="text"
                                                            required
                                                            className="form-control"
                                                            placeholder="Name"
                                                            value={username}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <input
                                                            name="email"
                                                            type="text"
                                                            className="form-control"
                                                            required
                                                            placeholder="Email"
                                                            value={email}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <textarea
                                                            name="message"
                                                            rows={4}
                                                            className="form-control"
                                                            required
                                                            placeholder="Message"
                                                            value={message}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                    <div className="text-right">
                                                        <button name="submit" onClick={handleSubmit} value="Submit" className="site-button btn-half">
                                                            <span>submit</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </motion.div>
                                        <motion.div
                                            className="col-lg-4 col-md-12 col-sm-12"
                                            initial="hidden"
                                            animate={controls}
                                            variants={animationVariants}
                                            transition={{ delay: 0.4, duration: 0.5 }}
                                        >
                                            <div className="contact-info block-shadow bg-white bg-center p-a40" style={{ backgroundImage: "url(images/background/bg-map.png)" }}>
                                                <div>
                                                    {/* TITLE START */}
                                                    <div className="section-head">
                                                        <div className="sx-separator-outer separator-left">
                                                            <div
                                                                className="sx-separator bg-white bg-moving bg-repeat-x"
                                                                style={{ backgroundImage: "url(images/background/cross-line2.png)" }}
                                                            >
                                                                <h3 className="sep-line-one">Have Questions</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* TITLE END */}
                                                    <div className="sx-icon-box-wraper left p-b30">
                                                        <div className="icon-xs">
                                                            <i className="fa fa-phone" />
                                                        </div>
                                                        <div className="icon-content">
                                                            <h5 className="m-t0">Phone number</h5>
                                                            <p>+91 96871 45451</p>
                                                        </div>
                                                    </div>
                                                    <div className="sx-icon-box-wraper left p-b30">
                                                        <div className="icon-xs">
                                                            <i className="fa fa-envelope" />
                                                        </div>
                                                        <div className="icon-content">
                                                            <h5 className="m-t0">Email address</h5>
                                                            <p>florailindustries@gmail.com</p>
                                                        </div>
                                                    </div>
                                                    <div className="sx-icon-box-wraper left">
                                                        <div className="icon-xs">
                                                            <i className="fa fa-map-marker" />
                                                        </div>
                                                        <div className="icon-content">
                                                            <h5 className="m-t0">Address info</h5>
                                                            <p>50 ft main road, Kothariya main road, Rajkot, Gujarat, India, 360002 </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </motion.div>
                                    </div>
                                )}
                            </InView>
                        </div>
                    </div>
                </div>

                {/* <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14767.80613399776!2d70.8158166!3d22.2798257!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3959b592a1862765%3A0x878880b6e96fd3a!2sFlorail%20Industries%20(Aluminium%20Railing%20Product%2C%20Glass%20Railing%20Rajkot%20Manufacturer%2C%20Slim%20Profile%20and%20Accessories)!5e0!3m2!1sen!2sin!4v1722657600432!5m2!1sen!2sin"
                    width="600"
                    height="450"
                    style={{ border: 0 }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                ></iframe> */}

                <div className="map-container">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14767.80613399776!2d70.8158166!3d22.2798257!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3959b592a1862765%3A0x878880b6e96fd3a!2sFlorail%20Industries%20(Aluminium%20Railing%20Product%2C%20Glass%20Railing%20Rajkot%20Manufacturer%2C%20Slim%20Profile%20and%20Accessories)!5e0!3m2!1sen!2sin!4v1722657600432!5m2!1sen!2sin"
                        width="600"
                        height="450"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        title="Google Maps"
                    ></iframe>
                </div>

                <div className="gmap-outline">
                    <GoogleMapReact bootstrapURLKeys={{ key: "AIzaSyAfY1DRbspf6E3jYUso-PeI_tdfRXA59i0" }} defaultCenter={defaultProps.center} defaultZoom={defaultProps.zoom}>
                        <AnyReactComponent lat={34.07328} lng={-118.25141} text={<i className="fa fa-map-marker" />} />
                    </GoogleMapReact>
                </div>
                {/* SECTION CONTENT END */}
            </div>
        </>
    );
};

export default ContactUs;
