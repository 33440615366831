import React from "react";
import { NavLink } from "react-router-dom";

import { useLocation } from "react-router-dom";

const withRouter = (Component) => {
    return (props) => {
        const location = useLocation();
        return <Component {...props} location={location} />;
    };
};

class Navigation extends React.Component {
    componentDidMount() {
        function loadScript(src) {
            return new Promise(function (resolve, reject) {
                var script = document.createElement("script");
                script.src = src;
                script.addEventListener("load", function () {
                    resolve();
                });
                script.addEventListener("error", function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            });
        }

        loadScript("./assets/js/mobilenav.js");
    }

    render() {
        const { location } = this.props;
        const items = [
            { name: "Aluminium Series", type: "aluminium" },
            { name: "Stainless Steel Series", type: "steel" },
        ];

        return (
            <>
                <ul className="nav navbar-nav">
                    <li className={location.pathname === "/" ? "active" : ""}>
                        <NavLink to={"/"}>Home</NavLink>
                    </li>
                    <li className={location.pathname === "/about-us" ? "active" : ""}>
                        <NavLink to={"/about-us"}>About us</NavLink>
                    </li>
                    <li className={location.pathname === "/product" ? "active" : ""}>
                        <NavLink to={"/product/aluminium"}>Product</NavLink>

                        <ul className="sub-menu" style={{ width: 250 }}>
                            {items.map((el, index) => (
                                <li key={index} style={{ width: 250 }}>
                                    <NavLink to={`/product/${el.type}`}>{el.name}</NavLink>
                                </li>
                            ))}
                        </ul>
                    </li>
                    <li className={location.pathname === "/gallery" ? "active" : ""}>
                        <NavLink to={"/gallery"}>Gallery</NavLink>
                    </li>
                    <li className={location.pathname === "/contact-us" ? "active" : ""}>
                        <NavLink to={"/contact-us"}>Contact us</NavLink>
                    </li>
                </ul>
            </>
        );
    }
}

export default withRouter(Navigation);
