import React, { useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Footer from "../Common/Footer";
import Banner from "../Elements/Banner";
import ShopSidebar from "../Elements/ShopSidebar";

export const products = [
    {
        image: require("./../../assets/page_media/product/Aluminium_Bottom_Profile_FR01.jpg"),
        title: "FR 01",
        sale: "no",
        cutprice: "",
        price: "36.00",
    },
    {
        image: require("./../../assets/page_media/product/Aluminium_Hand_Rail_FR51.jpg"),
        title: "FR 51",
        sale: "yes",
        cutprice: "45.00",
        price: "36.00",
    },
    {
        image: require("./../../assets/page_media/product/Aluminum_Railing_Bracket_FR121.jpg"),
        title: "FR 121",
        sale: "no",
        cutprice: "",
        price: "29.00",
    },
    {
        image: require("./../../assets/page_media/product/Aluminum_Railing_Bracket_FR_101.jpg"),
        title: "FR 101",
        sale: "no",
        cutprice: "",
        price: "59.00",
    },
];

var bnrimg = require("./../../assets/page_media/product/ProductBanner.jpg");

const ShopGrid = ({ typeWiseCategory, productCategoryWise }) => {
    useEffect(() => {
        const loadScript = (src) => {
            return new Promise((resolve, reject) => {
                const script = document.createElement("script");
                script.src = src;
                script.addEventListener("load", () => {
                    resolve();
                });
                script.addEventListener("error", (e) => {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            });
        };

        loadScript("./assets/js/custom.js");
    }, []);

    const { type, category } = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        !category && navigate(`/product/${type}/${Object.keys(productCategoryWise?.[type])[0]}`);
    }, [productCategoryWise, category]);

    const handleClick = (item) => {
        navigate("/product-detail", { state: { product: item } });
    };

    return (
        <>
            <div className="page-content">
                <Banner title="Product" pagename="Product" bgimage={bnrimg} />
                {/* SHOP SECTION START */}
                <div className="section-full p-t120 p-b90 bg-white mobile-page-padding">
                    <div className="container">
                        <div className="product-filter-wrap d-flex justify-content-between align-items-center m-b30"></div>
                        <div className="row justify-content-center" style={{ display: "flex", flexDirection: "row-reverse" }}>
                            <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 m-b30">
                                <div className="row">
                                    {productCategoryWise?.[type]?.[category]?.map((item, index) => {
                                        return (
                                            <div key={index} className="col-lg-4 col-md-4 m-b30">
                                                <div className="sx-box sx-product-box overflow-hide">
                                                    <div className="sx-thum-bx sx-img-overlay1">
                                                        <img src={item?.cloudinery_response?.[0]?.secure_url} alt="" />
                                                    </div>
                                                    <div className="sx-info p-t20 text-center">
                                                        <h5 className="sx-title">
                                                            <div onClick={() => handleClick(item)} key={item._id} style={{ cursor: "pointer" }}>
                                                                {item.name}
                                                            </div>
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            {/* SIDE BAR START */}
                            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 sticky_column m-b30">
                                <ShopSidebar typeWiseCategory={typeWiseCategory[type]} />
                            </div>
                            {/* SIDE BAR END */}
                        </div>
                    </div>
                </div>
                {/* SHOP SECTION END */}
            </div>
        </>
    );
};

export default ShopGrid;
