import React from "react";
import CountUp from "react-countup";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

var bgimg1 = require("./../../assets/page_media/home/Statictis.jpg");
var bgimg2 = require("./../../images/background/bg-5.png");
var bgimg3 = require("./../../images/background/cross-line2.png");
var bgimg4 = require("./../../assets/page_media/home/Download_catalogue.jpg");

const fadeInUp = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
    transition: { duration: 0.6 },
};

const Testimonials1 = () => {
    const handleDownload = () => {
        // Path to the PDF file
        const filePath = "../../assets/page_media/home/Florail Brochure.pdf";

        // Create a temporary link element
        const link = document.createElement("a");
        link.href = filePath;
        link.download = "Florail.pdf"; // Specify the file name for the downloaded file

        // Append the link to the body (necessary for Firefox)
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);
    };

    const [ref1, inView1] = useInView({ triggerOnce: true });
    const [ref2, inView2] = useInView({ triggerOnce: true });
    const [ref3, inView3] = useInView({ triggerOnce: true });
    const [ref4, inView4] = useInView({ triggerOnce: true });
    const [ref5, inView5] = useInView({ triggerOnce: true });

    return (
        <>
            <div className="section-full container-fluid">
                <div className="section-content">
                    <div className="row">
                        <motion.div
                            ref={ref1}
                            className="col-xl-6 col-lg-6 col-md-12 bg-gray bg-cover bg-no-repeat"
                            style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                            initial="hidden"
                            animate={inView1 ? "visible" : "hidden"}
                            variants={fadeInUp}
                        >
                            <div className="sx-left-part mobile-page-padding">
                                {/* TITLE START */}
                                <div className="section-head">
                                    <div className="sx-separator-outer separator-left">
                                        <div className="sx-separator bg-white bg-moving bg-repeat-x" style={{ backgroundImage: "url(" + bgimg3 + ")" }}>
                                            <h3 className="sep-line-one">Achievement</h3>
                                        </div>
                                    </div>
                                </div>
                                {/* TITLE END */}
                                <div className="counter-blocks">
                                    <div className="row">
                                        <motion.div
                                            ref={ref2}
                                            className="col-xl-6 col-lg-6 col-md-6 col-sm-6 m-b30"
                                            initial="hidden"
                                            animate={inView2 ? "visible" : "hidden"}
                                            variants={fadeInUp}
                                            transition={{ delay: 0.2 }}
                                        >
                                            <div className="sx-count  text-black sx-icon-box-wraper bg-repeat  bg-white p-a30" style={{ backgroundImage: "url(" + bgimg2 + ")" }}>
                                                <h2 className="st-count-number sx-text-primary text-left">
                                                    <span className="counter">
                                                        <CountUp end={24} duration={5} />
                                                    </span>
                                                </h2>
                                                <h4 className="m-tb0">Years of Experience</h4>
                                            </div>
                                        </motion.div>
                                        <motion.div
                                            ref={ref3}
                                            className="col-xl-6 col-lg-6 col-md-6 col-sm-6 m-b30"
                                            initial="hidden"
                                            animate={inView3 ? "visible" : "hidden"}
                                            variants={fadeInUp}
                                            transition={{ delay: 0.4 }}
                                        >
                                            <div className="sx-count text-black sx-icon-box-wraper bg-repeat  bg-white p-a30" style={{ backgroundImage: "url(" + bgimg2 + ")" }}>
                                                <h2 className="st-count-number sx-text-primary text-left">
                                                    <span className="counter">
                                                        <CountUp end={340} duration={5} />
                                                    </span>
                                                </h2>
                                                <h4 className="m-tb0">Project Completed</h4>
                                            </div>
                                        </motion.div>
                                        <motion.div
                                            ref={ref4}
                                            className="col-xl-6 col-lg-6 col-md-6 col-sm-6 m-b30"
                                            initial="hidden"
                                            animate={inView4 ? "visible" : "hidden"}
                                            variants={fadeInUp}
                                            transition={{ delay: 0.6 }}
                                        >
                                            <div className="sx-count text-black sx-icon-box-wraper bg-repeat  bg-white p-a30" style={{ backgroundImage: "url(" + bgimg2 + ")" }}>
                                                <h2 className="st-count-number sx-text-primary text-left">
                                                    <span className="counter">
                                                        <CountUp end={86} duration={5} />
                                                    </span>
                                                </h2>
                                                <h4 className="m-tb0">Products</h4>
                                            </div>
                                        </motion.div>
                                        <motion.div
                                            ref={ref5}
                                            className="col-xl-6 col-lg-6 col-md-6 col-sm-6 m-b30"
                                            initial="hidden"
                                            animate={inView5 ? "visible" : "hidden"}
                                            variants={fadeInUp}
                                            transition={{ delay: 0.8 }}
                                        >
                                            <div className="sx-count text-black sx-icon-box-wraper bg-repeat  bg-white p-a30" style={{ backgroundImage: "url(" + bgimg2 + ")" }}>
                                                <h2 className="st-count-number sx-text-primary text-left">
                                                    <span className="counter">
                                                        <CountUp end={36} duration={5} />
                                                    </span>
                                                    <span>K</span>
                                                </h2>
                                                <h4 className="m-tb0">Categories</h4>
                                            </div>
                                        </motion.div>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                        <motion.div
                            ref={ref5}
                            className="col-xl-6 col-lg-6 col-md-12 bg-white bg-repeat"
                            style={{ backgroundImage: "url(" + bgimg4 + ")" }}
                            initial="hidden"
                            animate={inView5 ? "visible" : "hidden"}
                            variants={fadeInUp}
                            transition={{ delay: 1 }}
                        >
                            <div className="sx-right-part mobile-page-padding  p-t80 p-b50">
                                {/* TITLE START */}
                                <div className="section-head">
                                    <div className="sx-separator-outer separator-left">
                                        <div
                                            className="sx-separator bg-white bg-moving bg-repeat-x"
                                            style={{ backgroundImage: "url(" + bgimg3 + ")", padding: "20px 60px 20px 20px", fontSize: "42px", fontWeight: 800 }}
                                        >
                                            <h3 className="" style={{ cursor: "pointer", fontSize: 25 }} onClick={handleDownload}>
                                                Download Catalogue
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Testimonials1;
